
.terms-content{
    max-width: 1120px;
    margin: auto;
    padding: 5px 20px 5px 20px;
}

.header-1{
    font-size: 2rem;
    line-height: 1.4;
    font-weight: 700;
    color: black;
    font-style: normal;
    word-wrap: break-word;
    font-family: Inter-Bold,Helvetica;
    /* max-inline-size: 30ch; */
    text-align: center;
}

.header-2{
    font-size: 1.5rem;
    line-height: 1.6;
    font-weight: 700;
    color: black;
    font-style: normal;
    word-wrap: break-word;
    font-family: Inter-Bold,Helvetica;
    /* max-inline-size: 30ch; */
    text-align: start;
    margin-top: 20px;
    display: block;
}

.header-3{
    font-size: 1.25rem;
    line-height: 1.6;
    font-weight: 700;
    color: black;
    font-style: normal;
    word-wrap: break-word;
    font-family: Inter-Bold,Helvetica;
    /* max-inline-size: 30ch; */
    text-align: start;
    margin-top: 10px;
    display: block;
}

.terms-content p{
    /* max-inline-size: 66ch; */
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    color: black;
    word-wrap: break-word;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Inter-Regular,Helvetica;
}

.sub-header{
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 700;
}

.sub-header-inline{
    display: flex;
    justify-content: start;
    align-items: baseline;
}

.sub-header-inline .header-3 {
    margin-right: 0.65rem;
}

@media only screen and (min-width: 768px) {
    .terms-content{
        padding: 30px 40px 10px 40px;
    }
    .description-heading{
        max-inline-size: 25ch;
        font-size: 1.2rem;
        text-align: start;
    }
    .header-1{
        font-size: 1.75rem;
        line-height: 1.4;
    }
    
    .header-2{
        font-size: 1.5rem;
        line-height: 1.4;

    }
    .header-3{
        font-size: 1.25rem;
        line-height: 1.4;

    }
    .terms-content p{
        font-size: .85rem;
        text-align: justify;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 992px) {
    .terms-content{
        padding: 50px 50px 10px 50px;
    }
    .header-1{
        font-size: 2rem;
        line-height: 1.4;
    }
    .header-2{
        font-size: 1.25rem;
        line-height: 1.6;
    }
    .header-3{
        font-size: 1rem;
        line-height: 1.6;
    }
    .terms-content p{
        font-size: .85rem;
        text-align: justify;
    }
}