
*{
    font-family: 'Inter';
    margin: 0;
    padding: 0;
    border: border-box;
}
html {
    font-size: clamp(1rem, 0.75rem + 1.25vw, 1.5rem);
}
.content{
    max-width: 1120px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* max-width: 1020px; */
}

.description-box{
    min-height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px 20px 5px 20px;
}

.description{
    /* border: 1px solid blue; */
    height: auto;
    min-height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: 600px; */
}

.description-heading{
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: 700;
    color: black;
    font-style: normal;
    word-wrap: break-word;
    font-family: Inter-Bold,Helvetica;
    /* max-inline-size: 30ch; */
    text-align: center;
}

.description-para{
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 400;
    color: black;
    word-wrap: break-word;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Inter-Regular,Helvetica;
}

.play-store{
    width: 8rem;
    height: 2rem;
    border-radius: 2rem;
    border: 1px solid #1B3D6E;
    background: #1B3D6E;
    display: flex;
    justify-content: space-evenly;
    align-items: center;   
}

.play-store-icon{
    color: white;
}

.play-store-text{
    color: white;
    align-items: center;
    font-size: 0.80rem;
}

.app1{
    margin: auto;
}

.app{
    /* border: 1px solid goldenrod; */
    height: 420px;
    width: auto;
    display: flex;
}

.app-logo{
    background-image: url("../assets/app-small.png");   
    width: 343px;
    background-repeat: no-repeat;
    position: relative;
}
.video{
    height: auto   !important;
    background: #204174;
    margin-top: -50px;
    width: 100%;
    z-index: -1;
    position: relative;
}

.video-border{
    border-radius: 50%/100px 50px 0 0;
    height: 50px;
    background: white;
    transform:rotate(180deg); 
    z-index: -1;
    position: relative;
}

.video-border-2{
    height: 50px;
    background: #204174;
}

.youtube-panel{
    width: 100%    !important;
    height: auto   !important;
    justify-content: center;
    margin-top: 50px;

}

.youtube-video{
    position: relative;
    align-items: center;
    width: 100%    !important;
    aspect-ratio: 16 / 9;
}

.journey-header{
    display: flex;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    justify-content: center;
    height: 5em;
    align-items: center;
    font-family: Inter-Bold,Helvetica;
}

.journey-description-box{
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
}

.journey-description{
    display: flex;
    overflow-x: auto;
}

.journey-step{
    display: block;
    width: 200px;
    margin-bottom: 15px;
}

.journey-step p {
    text-align: center;
    font-size: 0.85rem;
}

.journey-dir-a{
    display: flex;
    flex-direction: column-reverse;
    height: 200px;
    width: 100px;
    background: 1px solid red;
}

.journey-dir-b{
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 100px;
}

.journey-direction{
    width: 90px;
    height: 100px;
    background-repeat: no-repeat;
}

.box{
    display: flex;
    width: 200px;
    height: 100px;
    justify-content: space-around;
}

.box-image{
    height: 80px;
    width: 80px;
    background-size: cover;
}
.overview{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.overview-heading{
    font-family: Inter-Bold,Helvetica;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0px;
    /* text-align: left; */
    text-align: center; 
    height: 6rem;
    justify-content: center;
    display: flex; 
    align-items: center;
    width: 100vw;    
}

.overview-content{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.overviw-image-box{
    width: 175px;
    height: 355px;
}
.overviw-image{
    background-image: url('../assets/overview-small.png');
    width: 175px;
    height: 355px;
    background-repeat: no-repeat;
}
.overview-description{
    display: block;
    margin-left: 20px;
    margin-right: 20px;
}
.overview-point{
    display: flex;
    border: 1px solid #E2E2E2;
    align-items: center;
    border-radius: 10px;
    height: 4rem;
}
.overview-subpoint{
    font-size: .85rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0px;
    text-align: left;   
}
.overview-image{
    height: 35px;
    width: 35px;
    margin-left: 20px;
    margin-right: 20px;
}

.overview-point p {
    font-size: .75rem;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0px;
    text-align: left;
    color: #777777;    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .description-heading{
        max-inline-size: 25ch;
        font-size: 1.2rem;
        text-align: start;
    }
    .description-para{
        max-inline-size: 30ch;
        font-size: 0.75rem;
    }  
    .play-store{
        width: 7rem;
    }
    .play-store-text{
        font-size: 0.65rem;
    }
}

@media only screen and (min-width: 992px) {
    .navbar,.overview, .overview-content {
        padding: 0px 30px 0px 30px;
    }
    .nav-logo {
        width: 228px;
        height: 51px;
    }
    .description-heading{
        max-inline-size: 25ch;
        font-size: 1.2rem;
        line-height: 1.2;
    }
    .description-para{
        font-size: .95rem;
        line-height: 1.4;
    }
    .play-store{
        width: 7rem;
    }
    .play-store-text{
        font-size: 0.65rem;
    }
    .app{
        height: 635px;
        max-width: 515px;
    }
    .app-logo{
        background-image: url("../assets/app-medium.png");
        overflow: visible;
        width: 515px;
    }
    .overviw-image{
        background-image: url('../assets/overview-medium.png');       
        width: 263px;
        height: 533px;
    }
    .overviw-image-box{
        min-width: 263px;
        min-height: 533px;
    }
    .overview-description{
        margin-left: 0;
        margin-right: 0;
    }
}