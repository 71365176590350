
.footer {
    background-color: #EFF1F4;
    margin-top: 1rem;
}

.foot-panel1 {
    height: 5em;
    border-bottom: 1px solid #E2E2E2;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    
}

.foot-panel2{
    height: 8rem;
    display: flex;
    justify-content: space-evenly;
}

ul {
    margin-top: 20px;
    font-size: .85rem;
    font-weight: 700;
    line-height: 1.2;
    /* color: black; */
    color: #444444;
}

ul a {
    display: block;
    margin-top: 20px;
    font-size: .75rem;
    line-height: 1.2;
    font-weight: 400;
    color: #777777;
}

.media-link{
    margin-top: 1.2em;
}

.media-link i {
    margin-right: 1rem;
    font-size: 1rem;
}

.foot-panel3{
    height: 5rem;
    border-top: 1px solid #E2E2E2;
    display: flex;
    font-size: .75rem;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    margin-left: 20px;
    margin-right: 20px;
    color: #444444;
}

.brand-icon{
    font-size: 1.2rem;
    margin-right: 1.2rem;
}

@media only screen and (min-width: 768px) {
    .play-store-text{
        font-size: 0.65rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 992px) {
    .play-store-text{
        font-size: 0.65rem;
    }
    .media-link i {
        margin-right: 1.25rem;
        font-size: 1.25rem;
    }
    .brand-icon{
        font-size: 1.4rem;
    }
}

