
.navbar{
    max-width: 1120px;
    margin: auto;
    min-height: 80px;    
    background-color: white;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px 5px 20px;
}
.nav-logo {
    max-width: 228px;
    max-height: 51px;
    width: 114px;
    height: 25px;
}

@media only screen and (min-width: 768px) {
    .nav-logo {
        width: 152px;
        height: 34px;
    }
    .navbar {
        padding: 0px 30px 0px 30px;
    }
}

@media only screen and (min-width: 992px) {
    .navbar {
        padding: 0px 30px 0px 30px;
    }
    .nav-logo {
        width: 228px;
        height: 51px;
    }
}
