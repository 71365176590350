.root{
  --white-color:#FFFFFF;
  --blue-color:#1B3D6E;
  --light-black-color:#1F1F1F;
  --black-color:#000000;
}

*,
*::after,
*::before{
  box-sizing: border-box;
}

body{
  margin: 0;
  font-family: "Inter", sans-serif;
}

.wrapper {
  margin: auto 90px;
}

a {
  text-decoration: none;
}